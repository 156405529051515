#total-stats {
  color: white;
  font-family: var(--font-family);
  font-size: var(--h5);
  height: 100%;
  width: 24%;
}

#total-stats hr {
  margin: 4px 0;
}

#stats-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: var(--h4);
  align-items: center;
  margin-bottom: 8px;
}

.stat_definition {
  color: var(--secondary-color);
}

.stat_question {
  cursor: pointer;
  border: 3px solid;
  font-size: var(--h6);
  font-weight: 900;
  padding: 1 12;
  border-radius: 50%;
}

.stat_definition {
  background-color: rgba(245, 222, 179, 0.6);
  margin: 8px 0 28px;
  padding-inline: 12px;
  border-radius: 16px;
  padding-block: 12px;
  position: relative;
}

.stat_title {
  margin-left: 32px;
  margin-top: 0px;
  font-size: var(--h6);
  padding-bottom: 2px;
}

.stat_help_icons,
.stat_panel_icons {
  position: absolute;
  left: 0px;
  width: 28px;
}
.stat_panel_icons {
  left: 2px;
  top: -1px;
  width: 21.5px;
}

.stat-detail {
  position: relative;
}

.stat-detail-value {
  position: relative;
  left: 30px;
}

@media screen and (max-height: 600px) {
  .stat_question {
    border: 2px solid;
    padding: 1 8;
  }

  .stat_title {
    margin-left: 24px;
  }

  .stat_definition {
    margin: 8px 0 24px;
    border-radius: 8px;
  }

  .stat_help_icons,
  .stat_panel_icons {
    width: 16px;
  }

  .stat_panel_icons {
    width: 10px;
  }
}
