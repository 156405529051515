

.tooltip {
  text-align: left;
  max-width: 50%;
} 


.button {
  align-items: center;
  background-color: transparent;
  background-position: center;
  background-repeat: repeat;
  background-size: auto;
  border: none;
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  font-family: var(--font-family);
  justify-content: center;
  outline: none;
  position: relative;
  text-align: center;
  white-space: pre-line;
}

.button-default {
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0;
  font-size: var(--h4);
  min-width: 296px;
  min-height: 128px;
}

.long-button {
  font-size: var(--h3);
  padding: 12px 16px;
}

.icon-button {
  filter: brightness(1.05);
  flex-direction: column;
  font-size: var(--h6);
  font-style: italic;
  margin-bottom: 20px;
  width: 100%;
}

.icon-button-img {
  height: 44;
  width: auto;
}

.button-screw {
  image-rendering: -webkit-optimize-contrast;
  height: 10px;
  position: absolute;
}

.imgbutton {
  cursor: pointer;
  height: auto;
  margin-bottom: 20px;
  width: 162;
}


.button:hover,
.imgbutton:hover,
.icon-button:hover {
  filter: brightness(1.15);
}

.disable-highlight:hover {
  /*This is the default brightness */
  filter: brightness(1.05);
}


.volume-container {
  position: relative;
  top: 65;
  left: 250;
}

.volume {
  position: absolute;
  top: -20;
  padding: 12 16;
  width: auto;
}

.volume-bar {
  position: absolute;
  left: -76;
  top: -50;
  padding: 8px 16px;
  border-radius: 25px;
}

@media screen and (max-height: 600px) {
  .button-default {
    min-width: 164px;
    min-height: 72px;
  }

  .long-button {
    padding: 3px 16px;
  }
  .icon-button {
    padding: 3px 16px;
    margin-bottom: 8px;
  }
  .icon-button-img {
    height: 20;
    width: auto;
  }

  .button-screw {
    height: 7px;
  }

  .volume-container {
    top: 28;
    left: 124;
  }

  .volume {
    top: 0;
    padding: 8 12;
  }

  .volume-bar {
    left: -36;
    top: -20;
    padding: 4px;
  }

  .volume-bar-input {
    width: 112 !important;
    height: 6px !important;
  }
}
