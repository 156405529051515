#dashboard {
  align-items: center;
  background-color: #000000d9;
  box-sizing: border-box;
  color: white;
  display: flex;
  height: 88;
  left: 0;
  margin: 0 auto;
  max-width: 1136;
  padding: 0 20px;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 10;
}

#account-name {
  font-size: var(--h5);
  padding-right: 76px;
}

#refresh-btn-container {
  display: flex;
  cursor: pointer;
}

#refresh-btn {
  height: auto;
  padding-right: 6px;
  width: 22px;
}

.acct_stats {
  display: flex;
  align-items: center;
  padding-right: 40px;
}

.energy-icon {
  padding-right: 4px;
  width: 20px;
}

.add-energy-btn {
  cursor: pointer;
  padding-left: 4px;
  width: 16px;
}

.dashboard_logo {
  bottom: 0;
  height: calc(100% - 12px);
  image-rendering: -webkit-optimize-contrast;
  margin: auto 0;
  position: absolute;
  right: 8;
  top: 0;
  width: auto;
}

.continuously-rotate {
  animation: rotation 1s infinite linear;
}

/* refresh animation */
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotatedon {
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transition: transform 1s ease-in-out;
}
.rotatedoff {
  transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transition: transform 1s ease-in-out;
}

.showdashboard {
  transition: top 1s;
  top: 0px;
}

.hidedashboard {
  transition: top 1s;
  top: -88px;
}

.loading_screen {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading_screen img {
  width: 25%;
}

#main_loading_text {
  color: white;
  font-size: var(--header);
  letter-spacing: 15;
  margin: 0;
  text-align: center;
  width: 100%;
  z-index: 2;
}

@media screen and (max-height: 600px) {
  #dashboard {
    height: 44;
    max-width: 680px;
    width: calc(100% - 16px);
  }

  #account-name {
    padding-right: 32px;
  }

  #refresh-btn {
    padding-right: 4px;
    width: 12px;
  }

  .acct_stats {
    padding-right: 20px;
  }

  .energy-icon {
    padding-right: 2px;
    width: 12px;
  }

  .add-energy-btn {
    width: 10px;
  }
}
