.exchange-title {
  color: var(--primary-color);
  font-family: var(--font-family);
  font-size: var(--h4);
}

.exchange-input {
  padding: 8 24;
  font-family: var(--font-family);
  font-size: var(--h2);
  width: 80%;
}

.exchange-buttons {
  display: flex;
  flex-direction: row;
}

.exchange-buttons button {
  min-width: 220;
  margin-right: 12;
}

.claims-title {
  color: var(--light-primary);
  font-size: var(--h2);
  position: "absolute";
  left: 170;
  top: 75;
}

@media screen and (max-height: 600px) {
  .exchange-input {
    padding: 8;
  }

  .exchange-buttons button {
    min-width: 120;
  }
}
