@font-face {
  font-family: "Phosphate-Solid";
  font-display: swap;
  src: url("./static/fonts/Phosphate-Solid.woff") format("woff");
}

@font-face {
  font-family: "Phosphate-Inline";
  font-display: swap;
  src: local("myfont"),
    url("./static/fonts/Phosphate-Inline.woff") format("woff");
}

:root {
  --font-family: "Phosphate-Solid";
  --primary-color: #821f19;
  --secondary-color: #c36531;
  --tertiary-color: #ffb452;
  --light-primary: #d8352a;
  --light: #f5e7be;
  --header: 90px;
  --title: 72px;
  --h1: 60px;
  --h2: 44px;
  --h3: 40px;
  --h4: 32px;
  --h5: 28px;
  --h6: 24px;
  --body: 20px;
  --label: 16px;
  --caption: 12px;
}

body {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: var(--font-family);
  font-size: var(--body);
  max-height: 657;
}

.container,
.container-row,
.column-centered,
.row-centered {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 1136;
  width: 100%;
}

.container-row,
.column-centered,
.row-centered {
  max-width: unset;
  justify-content: center;
}

.container-row,
.row-centered {
  flex-direction: row;
}

@media screen and (max-height: 600px) {
  :root {
    --header: 64px;
    --title: 44px;
    --h1: 32px;
    --h2: 24px;
    --h3: 24px;
    --h4: 20px;
    --h5: 17px;
    --h6: 14px;
    --body: 10px;
    --label: 9px;
    --caption: 8px;
  }

  body {
    background-size: cover;
  }

  .container {
    max-width: calc(100% - 16px);
  }
}

/*
    SCROLLBAR STYLES
*/

::-webkit-scrollbar {
  width: 10px;
}

@media only screen and (max-width: 1136px) {
  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgb(255, 250, 203);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(195, 101, 49);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(195, 101, 49);
}
