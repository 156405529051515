.nft_slot {
  position: relative;
  z-index: 3;
  cursor: pointer;
  width: auto;
}

.nft_slot_label {
  position: absolute;
  bottom: 0;
  width: auto;
  right: -4;
  font-size: var(--h6);
}

.nft_slot_label img {
  width: 40px;
}

.nft_slot_card_display {
  height: 100%;
  display: flex;
  object-fit: contain;
  width: 100%;
}

@media screen and (max-height: 600px) {
  .nft_slot_label img {
    width: 32px;
  }
}
