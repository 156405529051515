#backdrop {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
}

.inventory-container {
  background-color: black;
  box-sizing: border-box;
  height: calc(100vh - 112px);
  max-width: 1136;
  padding: 20;
  position: relative;
  top: 12px;
  width: 100%;
  z-index: 20;
}

.inventory-details {
  display: flex;
  height: 40%;
  margin-bottom: 12;
  justify-content: space-between;
  width: 100%;
  max-height: 200;
}

.inventory-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 8;
  width: 100%;
}

#stat-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 16px;
  row-gap: 8px;
  column-gap: 8px;
}

.stat-detail {
  font-size: var(--body);
}

#equipped {
  max-width: 140px;
}

#equipped img {
  height: 100%;
  width: auto;
}

#left-stat-buttons {
  display: flex;
  flex-direction: column;
  justify-content: end;
  max-width: 170;
  width: 100%;
}

#left-stat-buttons button {
  font-size: var(--body);
  line-height: var(--h6);
  width: 100%;
}

.inventory-upgrades {
  grid-auto-rows: calc((100% / 2) - 4px);
  max-width: 144;
}

.inventory-upgrades .selection_spot_thumbnail,
.inventory-upgrades .selection_spot_thumbnail img {
  height: 100%;
  width: auto;
}

.inventory-slots {
  display: flex;
  justify-content: space-between;
  height: calc(60% - 8px);
  width: 100%;
}

.inventory_left,
.inventory_right {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 140px;
  row-gap: 12px;
  column-gap: 12px;
  max-width: calc(70% - 32px);
  max-width: 40%;
}

.inventory_left {
  grid-template-columns: repeat(3, 1fr);
  max-width: 30%;
}

@media screen and (max-height: 600px) {
  .inventory-container {
    height: calc(100vh - 64px);
    padding: 12;
    top: 8;
    width: calc(100% - 16px);
  }

  .inventory-details {
    margin-bottom: 8;
  }

  #left-stat-buttons {
    max-width: 112;
  }

  #stat-container {
    grid-auto-rows: 8px;
    row-gap: 4px;
    column-gap: 4px;
  }

  .inventory_left,
  .inventory_right {
    grid-auto-rows: calc(100% / 2);
    row-gap: 4px;
    column-gap: 4px;
  }
}
