.main_lobby {
  flex-wrap: nowrap;
  margin-top: 16px;
}

.side_buttons {
  width: 160;
}

.side_buttons .icon-button-img {
  padding-top:7.5px;
}

.main_lobby_center {
  flex-wrap: nowrap;
  margin: 0 32 16;
  width: auto !important;
}

.selection_large .selection_spot_thumbnail {
  height: 288;
  width: 208;
}

.main_lobby_upgrades {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 140px;
  row-gap: 8px;
  column-gap: 8px;
  width: auto;
}

.close-container {
  margin: 4px auto;
  height: 111;
  z-index: 5;
}

.close-container img {
  height: 100%;
  margin: 0;
  width: auto;
}

.join-entries {
  color: #955917;
}

.join-entries input {
  border: none;
  border-radius: 4px;
  font-size: var(--label);
  margin-inline: 4px;
  outline: none;
  padding: 4 8;
  text-align: center;
  width: 100;
}

.joins-controls {
  background-color: #821f19;
  color: white;
  border: none;
  padding: 4 8;
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (max-height: 600px) {
  .main_lobby {
    margin-top: 8px;
  }

  .main_lobby_center {
    margin: 0 16 8;
  }

  .side_buttons {
    width: 92;
  }

  .selection_large .selection_spot_thumbnail {
    height: 156;
    width: 112;
  }

  .main_lobby_upgrades {
    row-gap: 4px;
    column-gap: 4px;
    grid-auto-rows: 70px;
  }

  .main_lobby_upgrades .selection_spot_thumbnail {
    height: 70;
    width: auto;
  }

  .join-entries input {
    padding: 0 2;
    width: 60;
  }

  .joins-controls {
    padding: 0 6;
  }

  .close-container {
    height: 56;
  }
}
