.countdown_timer,
.message_frame {
  color: var(--primary-color);
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.special_frame {
  min-height: 128px;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0;
  min-width: 296px;
  min-height: 128px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media screen and (max-height: 600px) {
  .special_frame {
    min-width: 164px;
    min-height: 72px;
  }
}

.special_frame_button {
  min-height: auto;
}

.message_frame_custom {
  z-index: 5;
}

.countdown_timer label {
  cursor: pointer;
  font-size: var(--h3);
  line-height: var(--h3);
}

.countdown_text {
  line-height: 25px;
  text-align: center;
}

.countdown_timer_button {
  flex-direction: column;
}

.message_frame_maintext {
  font-size: var(--h1);
}

.message_frame_subtext {
  font-size: var(--label);
  position: relative;
  top: -8;
  white-space: pre-line;
}

.no-cursor {
  cursor: default;
}

.no-cursor:hover {
  filter: brightness(1);
}
