#tokens-container {
  color: var(--light-primary);
  text-align: center;
  width: 100%;
  font-size: var(--h5);
}

.nft_rewards_container {
  text-align: center;
  position: relative;
  padding-top: 5px;
  height: 275px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.imageDiv img {
  box-shadow: 1px 1px 10px #999;
  margin: 2px;
  max-height: 50px;
  cursor: pointer;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: top;
}

.nft_card {
  min-width: 200px;
  height: 275px;
  display: inline-block;
}
