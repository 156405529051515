.market_panel_container {
  position: absolute !important;
  scrollbar-color: var(--primary-color) var(--light);
  scrollbar-width: 10px;
  overflow: auto;
}

.market_panel {
  display: grid;
  grid-gap: 1rem 0.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0.5rem 1rem 1rem;
}

.market_cell {
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  padding: 12px;
  text-align: center;
  width: calc(100% - 4px);
}

.market_name {
  background-color: white;
  border-radius: 2px;
  color: var(--light-primary);
  height: 48;
  margin-bottom: 8;
  padding: 4;
  text-decoration: none;
  width: 100%;
}

.market_img {
  box-sizing: border-box;
  height: 240;
  width: 100%;
  border-radius: 2px;
  object-fit: contain;
  background-color: #000000d9;
  margin-bottom: 8;
  padding: 4 8;
}

.market_left {
  color: white;
  letter-spacing: 1;
  margin-bottom: 8;
}

.market_button {
  font-size: var(--label);
  min-height: 48;
  width: 100%;
}
.market-tabs {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-style: normal;
  padding: 0px;
  margin-bottom: 0;
  margin-left: 8px;
  flex-wrap: wrap;
}

.market-tabs .highlight-on {
  filter: brightness(1.15);
  text-decoration: underline;
}

.market-tabs .long-button,
.market-wallet-display {
  font-size: var(--h6);
}

.market-tabs .inventory-header-return {
  margin-bottom: 8px;
}

.market-top-button {
  cursor: auto;
  flex-direction: row;
  margin-left: 8px;
  margin-bottom: 0;
  padding: 4px 16px;
}

.market-top-button .icon-button-img {
  width: 32px;
  height: 32px;
}

@media screen and (max-height: 600px) {
  .market_name {
    height: 32;
  }

  .market_img {
    height: 164;
  }

  .market_button {
    font-size: var(--label);
    min-height: 40;
    width: 100%;
  }

  .market-top-button .icon-button-img {
    width: 20px;
    height: 20px;
  }
}
