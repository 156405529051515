.damagecrack {
  position: absolute;
  width: 200px;
  height: 200px;
}

#damage-text {
  text-align: center;
  font-size: var(--header);
  font-family: var(--font-family);
  position: absolute;
  z-index: 100;
  color: var(--light);
  right: -100;
  -webkit-text-stroke: 5px var(--light-primary);
  -webkit-text-fill-color: var(--light);
}

.damage-fade {
  animation: damageFade 2s;
  opacity: 0;
}

@keyframes damageFade {
  from {
    top: 20px;
    opacity: 1;
  }
  to {
    top: -50px;
    opacity: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes damageFade {
  from {
    top: 20px;
    opacity: 1;
  }
  to {
    top: -50px;
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes damageFade {
  from {
    top: 20px;
    opacity: 1;
  }
  to {
    top: -50px;
    opacity: 0;
  }
}

/* Internet Explorer */
@-ms-keyframes damageFade {
  from {
    top: 20px;
    opacity: 1;
  }
  to {
    top: -50px;
    opacity: 0;
  }
}

/* Opera < 12.1 */
@-o-keyframes damageFade {
  from {
    top: 20px;
    opacity: 1;
  }
  to {
    top: -50px;
    opacity: 0;
  }
}
