.modal-container {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.64);
  z-index: 200;
  display: flex;
  justify-content: center;
}

.modal {
  align-items: center;
  background-color: var(--light);
  border-radius: 8px;
  box-sizing: border-box;
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  height: 80%;
  justify-content: center;
  margin: auto;
  padding: 60 20 12;
  position: relative;
  width: 72%;
}

.modal-close {
  background-color: var(--primary-color);
  border: none;
  color: white;
  cursor: pointer;
  font-family: inherit;
  font-size: var(--h5);
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 60;
}

.modal-title {
  background-color: var(--secondary-color);
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: var(--h2);
  width: calc(100% - 48px);
  min-height: 72;
  top: -36;
  margin-bottom: 20;
  overflow: hidden;
  padding: 8px 16px;
  position: absolute;
}

.modal-content {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding-right: 12px;
  width: calc(100% - 24px);
}

.modal-button {
  line-height: var(--h3);
  margin-top: 20px;
  padding: 12px 20px;
}

@media screen and (max-height: 600px) {
  .modal {
    height: 80%;
    margin: auto;
    padding: 32 12 12;
    width: 72%;
  }

  .modal-close {
    width: 32;
  }

  .modal-title {
    min-height: 40;
    top: -24;
  }

  .modal-content {
    width: calc(100% - 40px);
  }

  .modal-button {
    margin-top: 12px;
    padding: 8px 12px;
  }
}
