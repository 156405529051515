.btn {
  cursor: pointer;
}

.selected_display {
  background-color: var(--light);
  height: 100%;
  display: flex;
  color: white;
  font-family: var(--font-family);
  margin-right: 24;
}

.selection_panel {
  display: grid;
  grid-gap: 1rem 0.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.selection_cell {
  max-width: 151px;
}

.selection_cell .btn {
  align-items: center;
  justify-content: center;
  height: 100%;
}

.selection_panel_container {
  scrollbar-color: var(--primary-color) var(--light);
  scrollbar-width: 10px;
}

.selection_panel a {
  display: flex;
  position: relative;
  float: left;
  width: 100%;
}

.selection_panel a.active {
  background-color: var(--light-red);
}

.selection_panel a.selected {
  /*background-color: var(--secondary-color);*/
}

.selection_panel a span {
  display: none;
  color: white;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid #a9a9a9;
  position: absolute;
  left: 4px;
  z-index: 99999;
  width: calc(100% - 8px);
  padding: 6px 8px;
  text-align: center;
  -webkit-box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.4);
  font-family: var(--font-family);
}

.selection_panel a span.stats {
  bottom: 5;
}

.selection_panel a span.state {
  top: 5;
}

.selection_panel a:hover span.stats,
.selection_panel a.active span.stats,
.selection_panel a.selected span.stats,
.selection_panel a.active span.state,
.selection_panel a.selected span.state {
  display: block;
}

.selection_panel a.selected > .selection_img {
  /* outline: 4px solid #c36531; */
  outline-offset: -4px;
}

.selection_img {
  /*background-color: black; removed black bg, so our loading img is visible*/
  height: 200;
  min-width: 140;
  margin: 4;
  object-fit: contain;
  width: calc(100% - 8px);
}

.multiple_select {
  height: 90%;
  overflow-y: auto;
  text-align: center;
  width: 168;
  padding: 12 0 32;
}

.panel_container {
  box-sizing: border-box;
  overflow: auto;
  height: 100%;
  background-color: black;
  padding: 12;
  width: 100%;
}

.selection_img_smaller {
  height: 160;
}

.nft_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.nft_info_display_container {
  width: auto;
  height: 400;
}

.nft_img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.nft_info {
  background-color: var(--secondary-color);
  min-width: 153px;
}

.nft_text_container {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgb(195, 101, 49, 0.8);
  box-sizing: border-box;
  text-align: center;
  padding: 4;
  font-size: var(--body);
}

.nft_text_name {
  background-color: var(--light-red);
  text-align: center;
  font-size: var(--body);
}

.nft_text_item {
  text-align: center;
}

.selection_modal_header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-size: var(--h5);
  color: white;
  justify-content: space-between;
  background-color: var(--primary-color);
  font-family: var(--font-family);
  text-align: center;
}

.selection_spot {
  cursor: pointer;
  position: relative;
}

.selection_spot_thumbnail {
  height: 140;
  width: 100;
}

.selection_spot_thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.selection_spot span {
  bottom: 0;
  color: white;
  font-size: var(--label);
  left: 0;
  margin: 0 auto;
  right: 0;
  width: 83;
}

#car_selection_spot {
  position: relative;
  cursor: pointer;
  height: 288;
  width: 208;
  margin: 0 32px;
}

#car_selection_spot span {
  box-sizing: border-box;
  left: 0;
  margin: 0 auto;
  right: 0;
  width: 208;
  z-index: 1;
}

#car_selection_spot span.car_name {
  font-family: var(--font-family);
  font-size: var(--body);
  top: 0;
  color: var(--tertiary-color);
}

#car_selection_spot span.switch_label {
  font-size: var(--h5);
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media screen and (max-height: 600px) {
  .selection_panel_container {
    scrollbar-width: 4px;
  }

  .panel_container .selection_panel {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .panel_container .selection_img {
    height: auto;
    width: calc(100% / 3);
    min-width: 80;
  }

  #car_selection_spot,
  .selection_spot_thumbnail {
    height: 156;
    width: 112;
  }
  #car_selection_spot {
    margin: 0 16px;
  }

  .nft_info {
    min-width: unset;
  }

  .multiple_select {
    width: 100;
  }

  .multiple_select .selection_img {
    min-width: 80;
    height: 80;
  }
}
