#center_console_root {
  display: flex;
  height: calc(100% - 88px);
  justify-content: center;
  margin: auto;
  top: 88px;
  position: relative;
  width: 100%;
  max-width: 1366;
}

.center_login {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: -88;
  flex-direction: column;
}

.center_login img {
  width: 320;
}

.center_login button {
  font-size: var(--title);
  min-width: 248;
}

.center_console_img {
  max-height: 488;
  top: 48;
  bottom: 0;
  position: absolute;
  max-width: 940;
}

#logout-btn {
  bottom: 40;
  cursor: pointer;
  height: 28;
  position: absolute;
  right: 64;
  width: auto;
  z-index: 10;
}

.container_centered {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: max-content;
}

.loading {
  color: white;
  left: 1262 / 2 - 279 / 3;
  position: absolute;
  top: 330;
  z-index: 1;
}

@media screen and (max-height: 600px) {
  #center_console_root {
    height: calc(100% - 44px);
    top: 44px;
  }

  .center_console_img {
    height: 84%;
    max-width: 84%;
    top: 28;
    width: auto;
  }

  #logout-btn {
    bottom: 20;
    height: 16;
    right: 32;
  }
}
