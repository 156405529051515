/* INVENTORY HEADER */
.inventory-header {
  align-items: center;
  background-color: black;
  box-sizing: border-box;
  color: white;
  display: flex;
  font-family: var(--font-family);
  height: 88;
  justify-content: space-between;
  left: 0;
  margin: 0 auto;
  max-width: 1136;
  padding: 0 20px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 20;
}

.inventory-title {
  font-size: var(--h1);
}

.inventory-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.inventory-header button {
  font-size: var(--body);
  min-height: 73;
}


.inventory-header-return {
  font-style: normal;
  padding: 0 20px;
  margin-bottom: 0;
  margin-left: 8px;
}

@media screen and (max-height: 600px) {
  .inventory-header {
    height: 44;
    max-width: calc(100% - 16px);
  }

  .inventory-header button {
    min-height: 33;
  }
}
