.container_row {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* height: calc(100% - 88px - 128px); */
  justify-content: center;
  width: 100%;
  aspect-ratio: 235/122;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  box-sizing: border-box;
  padding: 88px 80px 64px;
  margin-top: -80px;
  max-width: 940;
}

/* UNPLAYABLE SCREEN */
.alert_container {
  background-color: #000000d9;
  border-radius: 8px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.4);
  color: var(--tertiary-color);
  font-family: var(--font-family);
  letter-spacing: 0.5px;
  padding: 40 16;
  position: relative;
  z-index: 100;
}

.alert_header {
  font-size: var(--h1);
  margin-bottom: 8;
}

.alert_subtext {
  color: white;
  font-size: var(--body);
  margin-bottom: 20;
}

.alert_subtext span {
  color: var(--tertiary-color);
}

.alert_link {
  color: inherit;
  font-size: var(--label);
  margin-bottom: 20;
}

.notice_larger {
  font-size: var(--h1);
}

.notice_large {
  font-size: var(--h2);
}

.notice_medium {
  font-size: var(--h5);
}

.nextRound_equipped {
  display: flex;
  text-align: center;
}

.nextRound_equipped img {
  margin: 0 8 !important;
  width: calc(208px * 0.7) !important;
}

.nextRound_rank {
  font-size: var(--h5);
}

.nextRound_rank span {
  margin-left: 8;
}

.winner_first {
  font-size: var(--title);
}

.winner_first span {
  font-size: var(--h3);
}

.battle_screen_icons {
  width: 1em;
}

.rewardpool_container {
  top: 44;
  left: -250;
  position: relative;
}

.rewardpool,
.ingame-marketplace {
  flex-direction: row;
  padding: 8 16;
  position: absolute;
  width: auto;
  font-style: normal;
}

.rewardpool {
  left: -64;
}

.ingame-marketplace_container {
  top: 80;
  position: relative;
  align-self: flex-start;
  left: -112;
}

@media screen and (max-height: 600px) {
  .container_row {
    max-width: 72%;
    height: auto;
    padding: 52px 52px 40px;
    margin-top: -52px;
  }

  .nextRound_equipped img {
    margin: 0 8 !important;
    width: calc(92px * 0.7) !important;
  }

  .nextRound_rank {
    font-size: var(--h6);
  }

  .rewardpool_container {
    top: 28;
    left: -116;
  }

  .ingame-marketplace_container {
    top: 40;
    left: -64;
  }
}
